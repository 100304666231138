import React from 'react';
import ReactHtmlParser from 'react-html-parser';
import PropTypes from 'prop-types';
import sanitizeHtml from 'sanitize-html';

/**
 * Clean HTML component
 * @component
 *
 * @param   {string} html   - Raw HTML content provided by Contentful
 * @returns {component}     - <CleanHTML html={string} />
 */

const CleanHTML = ({ html }) => {
  /**
   * Sanitize HTML function
   * @function
   *
   * @param   {string} dirty  - Raw HTML content
   * @returns {function}      - dirty HTML is pass and sanitized through the sanitize-html plugin
   */
  const clean = dirty =>
    sanitizeHtml(dirty, {
      allowedAttributes: {
        a: ['href', 'rel', 'target'],
        img: ['alt', 'src', 'srcset'],
      },
      allowedTags: [
        'a',
        'b',
        'br',
        'blockquote',
        'h1',
        'h2',
        'h3',
        'h4',
        'i',
        'img',
        'em',
        'li',
        'ol',
        'p',
        'span',
        'strong',
        'ul',
      ],
    });

  return <>{ReactHtmlParser(clean(html))}</>;
};

CleanHTML.propTypes = {
  html: PropTypes.string.isRequired,
};

export default CleanHTML;
